import { parseBytes32String } from '@ethersproject/strings'
import { Currency, Token } from '@uniswap/sdk-core'
import { arrayify } from 'ethers/lib/utils'
import { useMemo } from 'react'
import { createTokenFilterFunction } from '../components/SearchModal/filtering'
import { ExtendedEther, WETH9_EXTENDED } from '../constants/tokens'
import { useAllLists, useCombinedActiveList, useInactiveListUrls } from '../state/lists/hooks'
import { WrappedTokenInfo } from '../state/lists/wrappedTokenInfo'
import { NEVER_RELOAD, useSingleCallResult } from '../state/multicall/hooks'
import { useUserAddedTokens } from '../state/user/hooks'
import { isAddress } from '../utils'
import { TokenAddressMap, useUnsupportedTokenList } from '../state/lists/hooks'

import { useActiveWeb3React } from './web3'
import { useBytes32TokenContract, useTokenContract } from './useContract'

// reduce token map into standard address <-> Token mapping, optionally include user added tokens
function useTokensFromMap(tokenMap: TokenAddressMap, includeUserAdded: boolean): { [address: string]: Token } {
  const { chainId } = useActiveWeb3React()
  const userAddedTokens = useUserAddedTokens()

  return useMemo(() => {
    if (!chainId) return {}

    // reduce to just tokens
    const mapWithoutUrls = Object.keys(tokenMap[chainId] ?? {}).reduce<{ [address: string]: Token }>(
      (newMap, address) => {
        newMap[address] = tokenMap[chainId][address].token
        return newMap
      },
      {}
    )

    if (includeUserAdded) {
      return (
        userAddedTokens
          // reduce into all ALL_TOKENS filtered by the current chain
          .reduce<{ [address: string]: Token }>(
            (tokenMap, token) => {
              tokenMap[token.address] = token
              return tokenMap
            },
            // must make a copy because reduce modifies the map, and we do not
            // want to make a copy in every iteration
            { ...mapWithoutUrls }
          )
      )
    }

    return mapWithoutUrls
  }, [chainId, userAddedTokens, tokenMap, includeUserAdded])
}

export function useAllTokens(): { [address: string]: Token } {
  // const allTokens = useCombinedActiveList()
  // const usdtTokenInfo202 = {
  //   address: "0x92382b934F79436fDf02699893CE2dA08A0156ca",
  //   chainId: 202,
  //   decimals: 6,
  //   name: "TetherToken",
  //   symbol: "USDT",
  //   logoURI: "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
  // }
  // const daiTokenInfo202 = {
  //   address: "0xa58E8A3E1EDb38c2490d7085AB061826781b7caf",
  //   chainId: 202,
  //   decimals: 18,
  //   name: "DAI",
  //   symbol: "DAI",
  //   logoURI: "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
  // }
  const usdtTokenInfo2026 = {
    address: "0x8d35B84657F4612D53a8C16BfEC050C8eB3eea94",
    chainId: 2026,
    decimals: 6,
    name: "TetherToken",
    symbol: "USDT",
    logoURI: "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
  }
  const daiTokenInfo2026 = {
    address: "0xeEDBbCfe513CA94606C2cE8626F5c489FD45a269",
    chainId: 2026,
    decimals: 18,
    name: "DAI",
    symbol: "DAI",
    logoURI: "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
  }
  const wethTokenInfo2026 = {
    address: "0x146Ad90D6Fd3281a1ae0dB2dBb9301E3dcA6F855",
    chainId: 2026,
    decimals: 18,
    name: "Wrapped Ether",
    symbol: "WETH",
    logoURI: "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
  }
  // const usdtTokenInfo11155111 = {
  //   address: "0xDff2f2226CcE71C4ab05F77FfB7EB66A5956C1CE",
  //   chainId: 11155111,
  //   decimals: 6,
  //   name: "TetherToken",
  //   symbol: "USDT",
  //   logoURI: "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
  // }
  // const daiTokenInfo11155111 = {
  //   address: "0xb851463CEDea326f6a138037A89B9677692C8712",
  //   chainId: 11155111,
  //   decimals: 18,
  //   name: "DAI",
  //   symbol: "DAI",
  //   logoURI: "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
  // }
  // const shibTokenInfo202 = {
  //   address: "0x1c086B8c31A9e3040a6b659d03d3ba3Ce390F37A",
  //   chainId: 202,
  //   decimals: 18,
  //   name: "Shiba Inu",
  //   symbol: "SHIB",
  //   logoURI: "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
  // }
  // const shibTokenInfo11155111 = {
  //   address: "0xf20c80d7e4aa4e69049f41ef9393c8cd62A5818d",
  //   chainId: 11155111,
  //   decimals: 18,
  //   name: "Shiba Inu",
  //   symbol: "SHIB",
  //   logoURI: "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
  // }
  const shibTokenInfo2026 = {
    address: "0xa31a421A97fca0160bCb3810DCc01F84B8197E3A",
    chainId: 2026,
    decimals: 18,
    name: "Shiba Inu",
    symbol: "SHIB",
    logoURI: "https://assets.coingecko.com/coins/images/849/thumb/district0x.png?1547223762"
  }
  // const tokenList202 = {
  //   keywords: ['hardcode'],
  //   logoURI: "ipfs://QmNa8mQkrNKp1WEEeGjFezDmDeodkWRevGFN8JCV7b4Xir",
  //   name: 'hardcode list',
  //   timestamp: "2022-01-01T00:00:00Z",
  //   tokens: [usdtTokenInfo202, daiTokenInfo202, shibTokenInfo202],
  //   version: { major: 1, minor: 0, patch: 0 }
  // }
  // const tokenList11155111 = {
  //   keywords: ['hardcode'],
  //   logoURI: "ipfs://QmNa8mQkrNKp1WEEeGjFezDmDeodkWRevGFN8JCV7b4Xir",
  //   name: 'hardcode list',
  //   timestamp: "2022-01-01T00:00:00Z",
  //   tokens: [usdtTokenInfo11155111, daiTokenInfo11155111],
  //   version: { major: 1, minor: 0, patch: 0 }
  // }
  const tokenList2026 = {
    keywords: ['hardcode'],
    logoURI: "ipfs://QmNa8mQkrNKp1WEEeGjFezDmDeodkWRevGFN8JCV7b4Xir",
    name: 'hardcode list',
    timestamp: "2022-01-01T00:00:00Z",
    tokens: [usdtTokenInfo2026, daiTokenInfo2026, shibTokenInfo2026, wethTokenInfo2026],
    version: { major: 1, minor: 0, patch: 0 }
  }
  const allTokens: TokenAddressMap = {
    // 202: {
    //   "0x92382b934F79436fDf02699893CE2dA08A0156ca": {
    //     token: new WrappedTokenInfo(usdtTokenInfo202, tokenList202),
    //     list: tokenList202
    //   },
    //   "0xa58E8A3E1EDb38c2490d7085AB061826781b7caf": {
    //     token: new WrappedTokenInfo(daiTokenInfo202, tokenList202),
    //     list: tokenList202
    //   },
    //   "0x1c086B8c31A9e3040a6b659d03d3ba3Ce390F37A": {
    //     token: new WrappedTokenInfo(shibTokenInfo202, tokenList202),
    //     list: tokenList202
    //   }
    // },
    // 11155111: {
    //   "0xDff2f2226CcE71C4ab05F77FfB7EB66A5956C1CE": {
    //     token: new WrappedTokenInfo(usdtTokenInfo11155111, tokenList11155111),
    //     list: tokenList11155111
    //   },
    //   "0xb851463CEDea326f6a138037A89B9677692C8712": {
    //     token: new WrappedTokenInfo(daiTokenInfo11155111, tokenList11155111),
    //     list: tokenList11155111
    //   },
    //   "0xf20c80d7e4aa4e69049f41ef9393c8cd62A5818d": {
    //     token: new WrappedTokenInfo(shibTokenInfo11155111, tokenList11155111),
    //     list: tokenList11155111
    //   }
    // },
    2026: {
      "0x8d35B84657F4612D53a8C16BfEC050C8eB3eea94": {
        token: new WrappedTokenInfo(usdtTokenInfo2026, tokenList2026),
        list: tokenList2026
      },
      "0xeEDBbCfe513CA94606C2cE8626F5c489FD45a269": {
        token: new WrappedTokenInfo(daiTokenInfo2026, tokenList2026),
        list: tokenList2026
      },
      "0xa31a421A97fca0160bCb3810DCc01F84B8197E3A": {
        token: new WrappedTokenInfo(shibTokenInfo2026, tokenList2026),
        list: tokenList2026
      },
      "0x146Ad90D6Fd3281a1ae0dB2dBb9301E3dcA6F855": {
        token: new WrappedTokenInfo(wethTokenInfo2026, tokenList2026),
        list: tokenList2026
      }
    }
  }
  return useTokensFromMap(allTokens, true)
}

export function useUnsupportedTokens(): { [address: string]: Token } {
  const unsupportedTokensMap = useUnsupportedTokenList()
  return useTokensFromMap(unsupportedTokensMap, false)
}

export function useSearchInactiveTokenLists(search: string | undefined, minResults = 10): WrappedTokenInfo[] {
  const lists = useAllLists()
  const inactiveUrls = useInactiveListUrls()
  const { chainId } = useActiveWeb3React()
  const activeTokens = useAllTokens()
  return useMemo(() => {
    if (!search || search.trim().length === 0) return []
    const tokenFilter = createTokenFilterFunction(search)
    const result: WrappedTokenInfo[] = []
    const addressSet: { [address: string]: true } = {}
    for (const url of inactiveUrls) {
      const list = lists[url].current
      if (!list) continue
      for (const tokenInfo of list.tokens) {
        if (tokenInfo.chainId === chainId && tokenFilter(tokenInfo)) {
          const wrapped = new WrappedTokenInfo(tokenInfo, list)
          if (!(wrapped.address in activeTokens) && !addressSet[wrapped.address]) {
            addressSet[wrapped.address] = true
            result.push(wrapped)
            if (result.length >= minResults) return result
          }
        }
      }
    }
    return result
  }, [activeTokens, chainId, inactiveUrls, lists, minResults, search])
}

export function useIsTokenActive(token: Token | undefined | null): boolean {
  const activeTokens = useAllTokens()

  if (!activeTokens || !token) {
    return false
  }

  return !!activeTokens[token.address]
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(currency: Currency | undefined | null): boolean {
  const userAddedTokens = useUserAddedTokens()

  if (!currency) {
    return false
  }

  return !!userAddedTokens.find((token) => currency.equals(token))
}

// parse a name or symbol from a token response
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/

function parseStringOrBytes32(str: string | undefined, bytes32: string | undefined, defaultValue: string): string {
  return str && str.length > 0
    ? str
    : // need to check for proper bytes string and valid terminator
    bytes32 && BYTES32_REGEX.test(bytes32) && arrayify(bytes32)[31] === 0
    ? parseBytes32String(bytes32)
    : defaultValue
}

// undefined if invalid or does not exist
// null if loading
// otherwise returns the token
export function useToken(tokenAddress?: string): Token | undefined | null {
  const { chainId } = useActiveWeb3React()
  const tokens = useAllTokens()

  const address = isAddress(tokenAddress)

  const tokenContract = useTokenContract(address ? address : undefined, false)
  const tokenContractBytes32 = useBytes32TokenContract(address ? address : undefined, false)
  const token: Token | undefined = address ? tokens[address] : undefined

  const tokenName = useSingleCallResult(token ? undefined : tokenContract, 'name', undefined, NEVER_RELOAD)
  const tokenNameBytes32 = useSingleCallResult(
    token ? undefined : tokenContractBytes32,
    'name',
    undefined,
    NEVER_RELOAD
  )
  const symbol = useSingleCallResult(token ? undefined : tokenContract, 'symbol', undefined, NEVER_RELOAD)
  const symbolBytes32 = useSingleCallResult(token ? undefined : tokenContractBytes32, 'symbol', undefined, NEVER_RELOAD)
  const decimals = useSingleCallResult(token ? undefined : tokenContract, 'decimals', undefined, NEVER_RELOAD)

  return useMemo(() => {
    if (token) return token
    if (!chainId || !address) return undefined
    if (decimals.loading || symbol.loading || tokenName.loading) return null
    if (decimals.result) {
      return new Token(
        chainId,
        address,
        decimals.result[0],
        parseStringOrBytes32(symbol.result?.[0], symbolBytes32.result?.[0], 'UNKNOWN'),
        parseStringOrBytes32(tokenName.result?.[0], tokenNameBytes32.result?.[0], 'Unknown Token')
      )
    }
    return undefined
  }, [
    address,
    chainId,
    decimals.loading,
    decimals.result,
    symbol.loading,
    symbol.result,
    symbolBytes32.result,
    token,
    tokenName.loading,
    tokenName.result,
    tokenNameBytes32.result,
  ])
}

export function useCurrency(currencyId: string | undefined): Currency | null | undefined {
  const { chainId } = useActiveWeb3React()
  const isETH = currencyId?.toUpperCase() === 'ETH'
  const token = useToken(isETH ? undefined : currencyId)
  const extendedEther = useMemo(() => (chainId ? ExtendedEther.onChain(chainId) : undefined), [chainId])
  const weth = chainId ? WETH9_EXTENDED[chainId] : undefined
  if (weth?.address?.toLowerCase() === currencyId?.toLowerCase()) return weth
  return isETH ? extendedEther : token
}
