import { useTopPools } from "graphql/data/useTopPools"
import { Table, Thead, Tbody, Tr, Th, Td, Box, Text } from '@chakra-ui/react';
import { useTopTxns } from "graphql/data/useTopTxns";
import { t } from '@lingui/macro'

export function TopTxTable() {

  const { topTxns, loading, error } = useTopTxns()

  function shortenAddress(address: string, chars = 4): string {
    if (address.length !== 42) {
      throw Error(`Invalid 'address' parameter '${address}'.`)
    }
  
    // leave enough space for the ellipsis
    if (chars < 1 || chars > 19) {
      throw Error(`Invalid 'chars' parameter '${chars}'.`)
    }
    return `${address.substring(0, chars + 2)}...${address.substring(42 - chars)}`
  }

  function getAbbreviatedTimeString(timestamp: number) {
    const now = Date.now()
    const timeSince = now - timestamp
    const secondsPassed = Math.floor(timeSince / 1000)
    const minutesPassed = Math.floor(secondsPassed / 60)
    const hoursPassed = Math.floor(minutesPassed / 60)
    const daysPassed = Math.floor(hoursPassed / 24)
    const monthsPassed = Math.floor(daysPassed / 30)
  
    if (monthsPassed > 0) {
      return t`${monthsPassed}mo ago`
    } else if (daysPassed > 0) {
      return t`${daysPassed}d ago`
    } else if (hoursPassed > 0) {
      return t`${hoursPassed}h ago`
    } else if (minutesPassed > 0) {
      return t`${minutesPassed}m ago`
    } else {
      return t`${secondsPassed}s ago`
    }
  }
  
  return (
    <Box width="100%">
      <Table width="100%">
        <Thead>
          <Tr>
            <Th>Time</Th>
            <Th>Type</Th>
            <Th isNumeric>USD</Th>
            <Th isNumeric>Token amount</Th>
            <Th isNumeric>Token amount</Th>
            <Th>Wallet</Th>
          </Tr>
        </Thead>
        <Tbody>
          {topTxns.map((tx: any, index: number) => (
            <Tr key={index}>
              <Td>{getAbbreviatedTimeString(tx.timestamp * 1000)}</Td>
              <Td>{tx.type} {tx.token0.symbol} {tx.type === "Swap" ? "for" : "to"} {tx.token1.symbol}</Td>
              <Td isNumeric>{Math.abs(parseFloat(tx.amountUSD)).toFixed(2)}</Td>
              <Td isNumeric>{Math.abs(parseFloat(tx.amount0)).toFixed(2)}</Td>
                <Td isNumeric>{Math.abs(parseFloat(tx.amount1)).toFixed(2)}</Td>
                <Td>{shortenAddress(tx.wallet)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}