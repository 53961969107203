import { gql, useQuery } from "@apollo/client";
import { FeeAmount, TICK_SPACINGS } from "@uniswap/v3-sdk";
import axios from "axios";
import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";



export function useTicksFromSubgraph(
    poolAddress: string,
) {

    const query = gql`{
        ticks(
          where: {
            poolAddress: "${poolAddress.toLowerCase()}",
            
          },
          orderBy: tickIdx,
            orderDirection: asc
        ) {
          tickIdx
          liquidityGross
          liquidityNet
        }
    }`

    const { loading, error, data } = useQuery(query);

    const result = useMemo(() => {
        const result = data?.ticks.map((t: any) => {
        return {
          tickIdx: t.tickIdx,
          liquidityGross: t.liquidityGross,
          liquidityNet: t.liquidityNet,
        }
        }) ?? []
        return result
  }, [data?.ticks, poolAddress])
  
  return { ticks: result, loading, error }

}


export function useAllV3Ticks(
    poolAddress: string,
) {

    const [subgraphTickData, setSubgraphTickData] = useState<any>([])
    const { ticks, error, loading: isLoading } = useTicksFromSubgraph(poolAddress)

    useEffect(() => {
        if (ticks.length) {
            setSubgraphTickData(ticks)
        }
    }, [ticks])

    return { ticks: subgraphTickData, isLoading, error }
}
