import { Box, Flex } from '@chakra-ui/react';
import { useProtocolData } from 'graphql/data/useProtocolData';
import React, { PureComponent, useMemo } from 'react';
import { Heading } from 'rebass';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, TooltipProps } from 'recharts';
  

export default function PoolGraphs() {

    const { entries, loading, error } = useProtocolData()

    const tvlEntries = useMemo(() => {
        return entries.map((entry: any) => {
            return {
                date: `${new Date(entry.date * 1000).getFullYear()}-${new Date(entry.date * 1000).getMonth() + 1}-${new Date(entry.date * 1000).getDate()}`,
                uv: parseFloat(entry.tvlUSD)
            }
        })
    }, [entries])

    const volumeEntries = useMemo(() => {
        return entries.map((entry: any) => {
            return {
                date: `${new Date(entry.date * 1000).getFullYear()}-${new Date(entry.date * 1000).getMonth() + 1}-${new Date(entry.date * 1000).getDate()}`,
                uv: parseFloat(entry.volumeUSD)
            }
        })
    }, [entries])

    const CustomTooltipTvl = (props: TooltipProps) => {
        if (props.active && props.payload && props.payload.length) {
          return (
            <div className="custom-tooltip">
              <p className="label">{`tvl : $${(props.payload[0].value as number).toFixed(2)}`}</p>
            </div>
          );
        }
      
        return null;
      };

        const CustomTooltipVolume = (props: TooltipProps) => {
            if (props.active && props.payload && props.payload.length) {
            return (
                <div className="custom-tooltip">
                <p className="label">{`volume : $${(props.payload[0].value as number).toFixed(2)}`}</p>
                </div>
            );
            }
        
            return null;
        };
      

    return (
        <Flex justify="space-between">
            <Box width="1000px" pr="2">
                <Heading fontFamily={"Inter Tight"} mb="30px" as="h4">Daily TVL</Heading>
            <AreaChart
                width={500}
                height={300}
                data={tvlEntries}
                margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
                }}
            >
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip content={<CustomTooltipTvl />}  />
                <Area type="monotone" dataKey="uv" stroke="#F7771C" fill="#F7771C" />
            </AreaChart>
            </Box>

            <Box width="1000px" pl="2">
            <Heading fontFamily={"Inter Tight"} mb="30px" as="h4">Daily Volume</Heading>
            <AreaChart
                width={500}
                height={300}
                data={volumeEntries}
                margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
                }}
            >
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip content={<CustomTooltipVolume />}/>
                <Area type="monotone" dataKey="uv" stroke="#F7771C" fill="#F7771C" />
            </AreaChart>
            </Box>
        </Flex>
    )
}
