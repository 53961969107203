import { RouteComponentProps } from "react-router-dom";
import { TopPoolTable } from "./PoolTable";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import { TopTokenTable } from "./TokenTable";
import { TopTxTable } from "./TopTxTable";
import PoolGraphs from "./PoolGraphs";

export default function Explore({ history }: RouteComponentProps) {
  return (
    <Box width="1000px">
      <PoolGraphs />

      <Tabs mt="50px" colorScheme="orange">
        <TabList>
          <Tab>Pools</Tab>
          <Tab>Tokens</Tab>
          <Tab>Transactions</Tab>
        </TabList>

        <TabPanels mt="40px">
          <TabPanel>
            <TopPoolTable />
          </TabPanel>
          <TabPanel>
            <TopTokenTable />
          </TabPanel>
          <TabPanel>
            <TopTxTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
