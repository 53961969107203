export enum SupportedChainId {
  // MAINNET = 1,
  // ROPSTEN = 3,
  // RINKEBY = 4,
  // GOERLI = 5,
  // KOVAN = 42,
  // ARBITRUM_ONE = 42161,
  // SEPOLIA = 11155111,
  // EDGELESS_TEST = 202,
  EDGELESS_MAINNET = 2026
}

export const NETWORK_LABELS: { [chainId in SupportedChainId | number]: string } = {
  // [SupportedChainId.MAINNET]: 'Mainnet',
  // [SupportedChainId.RINKEBY]: 'Rinkeby',
  // [SupportedChainId.ROPSTEN]: 'Ropsten',
  // [SupportedChainId.GOERLI]: 'Görli',
  // [SupportedChainId.KOVAN]: 'Kovan',
  // [SupportedChainId.ARBITRUM_ONE]: 'Arbitrum One',
  // [SupportedChainId.SEPOLIA]: 'Sepolia',
  // [SupportedChainId.EDGELESS_TEST]: 'Edgeless Test',
  [SupportedChainId.EDGELESS_MAINNET]: 'Edgeless'
}
