import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

function getEpochSecondsOfTodayAtMidnightGMT(): number {
  const now = new Date();
  const startOfTodayGMT = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
  return Math.floor(startOfTodayGMT.getTime() / 1000);
}

function getEpochSecondsOfLastHour(): number {
  const now = new Date();
  const lastHour = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours() - 1));
  return Math.floor(lastHour.getTime() / 1000);
}

export function useTopPools() {
  const dayDataTimestamp = getEpochSecondsOfTodayAtMidnightGMT()
  const hourDataTimestamp = getEpochSecondsOfLastHour()

  const poolsQuery = gql`
  {
    pools(
      orderBy: totalValueLockedUSD, 
      orderDirection: desc,
      first: 100
    ) {
      id
      sqrtPrice
      tick
      liquidity
    token0 {
      symbol
      id
      decimals
      name
    }
    token1 {
      symbol
      id
      decimals
      name
    }
    feeTier
    txCount
    totalValueLockedUSD
    poolDayData(where: {date_gte: ${dayDataTimestamp}}) {
      volumeUSD
      date
    }
    poolHourData(where: {periodStartUnix_gte: ${hourDataTimestamp}}) {
      volumeUSD
      periodStartUnix
    }
  }
}`

  const { loading, error, data } = useQuery(poolsQuery);
  const topPools = useMemo(() => {
    const pools = data?.pools.map((pool: any) => {
      return {
        id: pool.id,
        sqrtPrice: pool.sqrtPrice,
        tick: pool.tick,
        liquidity: pool.liquidity,
        token0: pool.token0.symbol,
        token1: pool.token1.symbol,
        token0Full: pool.token0,
        token1Full: pool.token1,
        txCount: pool.txCount,
        fee: pool.feeTier,
        tvl: pool.totalValueLockedUSD,
        volume24h: pool.poolDayData.length > 0 ? pool.poolDayData[0].volumeUSD : 0,
        volume1h: pool.poolHourData.length > 0 ? pool.poolHourData[0].volumeUSD : 0,
      }
    }) ?? []
    return pools
  }, [data?.pools])
  
  return { topPools, loading, error }
}