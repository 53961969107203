import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

function getEpochSecondsOfTodayAtMidnightGMT(): number {
  const now = new Date();
  const startOfTodayGMT = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
  return Math.floor(startOfTodayGMT.getTime() / 1000);
}

export function useTopTokens() {
  const dayDataTimestamp = getEpochSecondsOfTodayAtMidnightGMT()

  const tokensQuery = gql`
  {
    tokens(
      orderBy: volumeUSD,
      orderDirection: desc
    ) {
      symbol
      name
      volumeUSD
      tokenDayData(where: {date_gte: ${dayDataTimestamp}}) {
        date
        volumeUSD
      }
    }
}`

  const { loading, error, data } = useQuery(tokensQuery);
  const topTokens = useMemo(() => {
    const tokens = data?.tokens.map((token: any) => {
      return {
        symbol: token.symbol,
        name: token.name,
        volume: token.volumeUSD,
        volume1d: token.tokenDayData.length > 0 ? token.tokenDayData[0].volumeUSD : 0,
      }
    }) ?? []
    return tokens
  }, [data?.tokens])
  
  return { topTokens, loading, error }
}