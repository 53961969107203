import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

export function usePoolDayData(id: string) {
  const query = gql`
  {
    poolDayDatas(
        where: {
          pool_: {
            id: "${id}"
          }
        },
        orderBy: date
    ) {
        date
        pool {
          token0 {
            symbol
          }
          token1 {
            symbol
          }
        }
        token0Price
        liquidity
        volumeUSD
    }
  }`

  const { loading, error, data } = useQuery(query);
  const entries = useMemo(() => {
    const entries = data?.poolDayDatas.map((d: any) => {
        return {
            date: d.date,
            token0Price: d.token0Price,
            liquidity: d.liquidity,
            volumeUSD: d.volumeUSD,
            token0: d.pool.token0.symbol,
            token1: d.pool.token1.symbol
        }
    }) ?? []
    return entries
  }, [data?.poolDayDatas, id])

  return { entries, loading, error }
}
