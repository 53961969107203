import { Box, Heading, Text } from "@chakra-ui/react";
import { FeeAmount, TICK_SPACINGS, TickMath, tickToPrice } from "@uniswap/v3-sdk";
import { Token } from "@uniswap/sdk-core";
import { BigNumber } from "ethers";
import { useTicksFromSubgraph } from "graphql/data/usePoolActiveLiquidity";
import { Bar, BarChart, Cell, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";

interface TickProcessed {
    tickIdx: number,
    liquidityActive: BigNumber,
    liquidityNet: BigNumber,
    price0: string,
    price1: string,
    isCurrent: boolean
}

interface LiquidityChartProps {
    pool: any
}

export function LiquidityChart(props: LiquidityChartProps) {

    const { ticks, loading, error } = useTicksFromSubgraph(props.pool.id)

    const tickIdxToTickDictionary: Record<string, any> = Object.fromEntries(
        ticks.map((graphTick: any) => [graphTick.tickIdx, graphTick])
    )

    const tickSpacing = TICK_SPACINGS[props.pool.fee as FeeAmount]

    const activeTickIdx = Math.floor(props.pool.tick / tickSpacing) * tickSpacing

    const token0Full = props.pool.token0Full
    const token1Full = props.pool.token1Full
    const tokenA = new Token(2026, token0Full.id, parseInt(token0Full.decimals), token0Full.symbol, token0Full.name)
    const tokenB = new Token(2026, token1Full.id, parseInt(token1Full.decimals), token1Full.symbol, token1Full.name)

    const activeTickProcessed: TickProcessed = {
        tickIdx: activeTickIdx,
        liquidityActive: props.pool.liquidity,
        liquidityNet: BigNumber.from(0),
        price0: tickToPrice(tokenA, tokenB, activeTickIdx).toFixed(6),
        price1: tickToPrice(tokenB, tokenA, activeTickIdx).toFixed(6),
        isCurrent: true
    }

    const currentTickInitialized = tickIdxToTickDictionary[activeTickIdx]
    if (currentTickInitialized !== undefined) {
        activeTickProcessed.liquidityNet = BigNumber.from(currentTickInitialized.liquidityNet)
    }

    let previousTickProcessed = {
        ...activeTickProcessed
    }

    let subsequentTicks: TickProcessed[] = []

    for (let i = 0; i < 100; i++) {
        const currentTickIdx = previousTickProcessed.tickIdx + tickSpacing

        if (currentTickIdx > TickMath.MAX_TICK) {
            break
        }

        const currentTickProcessed = {
            liquidityActive: previousTickProcessed.liquidityActive,
            tickIdx: currentTickIdx,
            liquidityNet: BigNumber.from(0),
            price0: tickToPrice(tokenA, tokenB, currentTickIdx).toFixed(6),
            price1: tickToPrice(tokenA, tokenB, currentTickIdx).toFixed(6),
            isCurrent: false
        }

        const currentTickInitialized = tickIdxToTickDictionary[currentTickIdx]

        if (currentTickInitialized !== undefined) {
            currentTickProcessed.liquidityNet = BigNumber.from(currentTickInitialized.liquidityNet)
            currentTickProcessed.liquidityActive = BigNumber.from(
                previousTickProcessed.liquidityActive).add(
                BigNumber.from(currentTickInitialized.liquidityNet)
            )
        }

        subsequentTicks.push(currentTickProcessed)
        previousTickProcessed = currentTickProcessed
    }

    previousTickProcessed = {
        ...activeTickProcessed
    }

    let previousTicks: TickProcessed[] = []

    for (let i = 0; i < 100; i++) {
        const currentTickIdx = previousTickProcessed.tickIdx - tickSpacing

        if (currentTickIdx < TickMath.MIN_TICK) {
            break
        }

        const currentTickProcessed = {
            liquidityActive: previousTickProcessed.liquidityActive,
            tickIdx: currentTickIdx,
            liquidityNet: BigNumber.from(0),
            price0: tickToPrice(tokenA, tokenB, currentTickIdx).toFixed(6),
            price1: tickToPrice(tokenA, tokenB, currentTickIdx).toFixed(6),
            isCurrent: false
        }

        const currentTickInitialized = tickIdxToTickDictionary[currentTickIdx]

        if (currentTickInitialized !== undefined) {
            currentTickProcessed.liquidityNet = BigNumber.from(currentTickInitialized.liquidityNet)
            currentTickProcessed.liquidityActive = BigNumber.from(
                previousTickProcessed.liquidityActive).sub(
                BigNumber.from(currentTickInitialized.liquidityNet)
            )
        }

        previousTicks.push(currentTickProcessed)
        previousTickProcessed = currentTickProcessed
    }


    const allProcessedTicks = previousTicks.reverse().concat(activeTickProcessed).concat(subsequentTicks)

    const abbreviateNumber = (value: number) => {
        let newValue = value;
        let suffix = "";
        if (value >= 1000) {
            let suffixNum = 0;
            while (newValue >= 1000) {
                newValue /= 1000;
                suffixNum++;
            }
            suffix = ["", "K", "M", "B", "T"][suffixNum];
        }
        return newValue.toFixed(1) + (suffix ?? "");
    }

    const chartTicks: any[] = allProcessedTicks.map((tickProcessed) => {
        return {...tickProcessed, liquidityActiveChart: parseFloat(tickProcessed.liquidityActive.toString()), showY: abbreviateNumber(parseFloat(tickProcessed.liquidityActive.toString()))}
    })

        return (<>
    <Box>
    <Heading fontFamily={"Inter Tight"} my="30px" fontSize={24}>Liquidity</Heading>
    <Text fontFamily={"Inter Tight"} fontSize={18}>x-axis represents price: 1 {token0Full.symbol} = xxx {token1Full.symbol}</Text>
    <BarChart  width={700}
                  height={300}
                  data={chartTicks}
                  margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                  }}>
                    {/* <YAxis dataKey="liquidityActiveChart" tickFormatter={abbreviateNumber}  /> */}
                    <XAxis dataKey="price0" />
        <Bar dataKey="liquidityActiveChart" fill="#2172E5">
            {chartTicks.map((entry, index) => {
                return (
                <Cell
                   key={`cell-${index}`}
                   fill={entry.isActive ? '#F51E87' : '#2172E5'}
                />
                )
            })}
        </Bar>
    </BarChart>
    </Box>

    </>)
}
