import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

export function useProtocolData() {
  const query = gql`
  {
    uniswapDayDatas(
      orderBy: date
    ) {
      date,
      tvlUSD,
      volumeUSD
    }
  }`

  const { loading, error, data } = useQuery(query );
  const entries = useMemo(() => {
    const entries = data?.uniswapDayDatas.map((d: any) => {
        return {
            date: d.date,
            tvlUSD: d.tvlUSD,
            volumeUSD: d.volumeUSD
        }
    }) ?? []
    return entries
  }, [data?.uniswapDayDatas])
  
  return { entries, loading, error }
}