import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

export function useTopTxns() {
  const txnsQuery = gql`
  {
    transactions(
      orderBy: timestamp,
      orderDirection: desc,
      first: 50
    ) {
      timestamp
      mints {
        amountUSD
        amount0
        amount1
        token0 {
          symbol
        }
        token1 {
          symbol
        }
        origin
      }
      burns {
        amountUSD
        amount0
        amount1
        token0 {
          symbol
        }
        token1 {
          symbol
        }
        origin
      }
      swaps {
        amountUSD
        amount0
        amount1
        token0 {
          symbol
        }
        token1 {
          symbol
        }
        origin
      }
    }
  }`

  const { loading, error, data } = useQuery(txnsQuery);
  const topTxns = useMemo(() => {
    const txns = data?.transactions.map((txn: any) => {
        let type;
        if (txn.mints.length > 0) {
          type = "Mint"
        } else if (txn.burns.length > 0) {
          type = "Burn"
        } else if (txn.swaps.length > 0) {
          type = "Swap"
        } else {
            return null
        }
        return {
            type,
            timestamp: txn.timestamp,
            amountUSD: txn.mints[0]?.amountUSD || txn.burns[0]?.amountUSD || txn.swaps[0]?.amountUSD,
            amount0: txn.mints[0]?.amount0 || txn.burns[0]?.amount0 || txn.swaps[0]?.amount0,
            amount1: txn.mints[0]?.amount1 || txn.burns[0]?.amount1 || txn.swaps[0]?.amount1,
            token0: txn.mints[0]?.token0 || txn.burns[0]?.token0 || txn.swaps[0]?.token0,
            token1: txn.mints[0]?.token1 || txn.burns[0]?.token1 || txn.swaps[0]?.token1,
            wallet: txn.mints[0]?.origin || txn.burns[0]?.origin || txn.swaps[0]?.origin
        }
    }) ?? []
    return txns.filter((txn: any) => txn !== null)
  }, [data?.transactions])
  
  return { topTxns, loading, error }
}