import { useTopPools } from "graphql/data/useTopPools";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  useDisclosure,
  Heading,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useMemo, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { usePoolDayData } from "graphql/data/usePoolDayData";
import { BigNumber } from "ethers";
import { LiquidityChart } from "./LiquidityChart";
import { EXPLORER_URL } from "../../constants";
export function TopPoolTable() {
  const { topPools } = useTopPools();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPool, setSelectedPool] = useState<any>(null);

  const { entries, loading, error } = usePoolDayData(selectedPool?.id);
  const CustomTooltipPrice = (props: TooltipProps) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            {`${selectedPool.token0} = ${(
              props.payload[0].value as number
            ).toFixed(2)} ${selectedPool.token1}`}{" "}
          </p>
        </div>
      );
    }
    return null;
  };

  const CustomTooltipVolume = (props: TooltipProps) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`volume : $${(
            props.payload[0].value as number
          ).toFixed(2)}`}</p>
        </div>
      );
    }
    return null;
  };

  const volumeEntries = useMemo(
    () =>
      entries.map((entry: any) => {
        return {
          date: `${new Date(entry.date * 1000).getFullYear()}-${
            new Date(entry.date * 1000).getMonth() + 1
          }-${new Date(entry.date * 1000).getDate()}`,
          uv: parseFloat(entry.volumeUSD),
        };
      }),
    [entries, selectedPool]
  );

  const priceEntries = useMemo(
    () =>
      entries.map((entry: any) => {
        return {
          date: `${new Date(entry.date * 1000).getFullYear()}-${
            new Date(entry.date * 1000).getMonth() + 1
          }-${new Date(entry.date * 1000).getDate()}`,
          uv: parseFloat(entry.token0Price),
        };
      }),
    [entries, selectedPool]
  );

  const liquidityEntries = useMemo(
    () =>
      entries.map((entry: any) => {
        return {
          date: `${new Date(entry.date * 1000).getFullYear()}-${
            new Date(entry.date * 1000).getMonth() + 1
          }-${new Date(entry.date * 1000).getDate()}`,
          uv: entry.liquidity,
        };
      }),
    [entries, selectedPool]
  );

  return (
    <Box width="100%">
      <Table width="100%">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Pool</Th>
            <Th isNumeric>Tx Count</Th>
            <Th isNumeric>TVL</Th>
            <Th isNumeric>Volume 24h</Th>
            <Th isNumeric>Volume 1h</Th>
          </Tr>
        </Thead>
        <Tbody>
          {topPools.map((pool: any, index: number) => (
            <Tr
              key={index}
              cursor={"pointer"}
              onClick={() => {
                setSelectedPool(pool);
                onOpen();
              }}
            >
              <Td>{index + 1}</Td>
              <Td>
                {pool.token0}/{pool.token1} ({pool.fee / 10000}%)
              </Td>
              <Td isNumeric>{pool.txCount}</Td>
              <Td isNumeric>${parseFloat(pool.tvl).toFixed(2)}</Td>
              <Td isNumeric>${parseFloat(pool.volume24h).toFixed(2)}</Td>
              <Td isNumeric>${parseFloat(pool.volume1h).toFixed(2)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Drawer size="xl" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent background="#212429">
            <DrawerCloseButton />
            <DrawerHeader>
              <a
                href={`${EXPLORER_URL}/address/${selectedPool?.id}`}
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                {selectedPool?.token0} / {selectedPool?.token1}
              </a>
            </DrawerHeader>
            <DrawerBody>
              <Box>
                <Heading fontFamily={"Inter Tight"} mb="30px" fontSize={24}>
                  Volume
                </Heading>
                <BarChart
                  width={700}
                  height={300}
                  data={volumeEntries}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip content={<CustomTooltipVolume />} />
                  <Bar dataKey="uv" stroke="#F7771C" fill="#F7771C" />
                </BarChart>
              </Box>

              <Box mt="40px">
                <Heading fontFamily={"Inter Tight"} mb="30px" fontSize={24}>
                  Price
                </Heading>
                <AreaChart
                  width={700}
                  height={300}
                  data={priceEntries}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip content={<CustomTooltipPrice />} />
                  <Area
                    type={"monotone"}
                    dataKey="uv"
                    stroke="#F7771C"
                    fill="#F7771C"
                  />
                </AreaChart>
              </Box>

              <LiquidityChart pool={selectedPool} />
            </DrawerBody>
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
}
