import { useTopPools } from "graphql/data/useTopPools";
import { Table, Thead, Tbody, Tr, Th, Td, Box, Text } from "@chakra-ui/react";
import { useTopTokens } from "graphql/data/useTopTokens";

export function TopTokenTable() {
  const { topTokens, loading, error } = useTopTokens();

  return (
    <Box width="100%">
      <Table width="100%">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Token</Th>
            <Th isNumeric>Volume</Th>
            <Th isNumeric>Volume 24h</Th>
          </Tr>
        </Thead>
        <Tbody>
          {topTokens.map((token: any, index: number) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>
                <a>{token.name}</a>{" "}
                <span style={{ fontSize: "12px" }}>{token.symbol}</span>
              </Td>
              <Td isNumeric>{parseFloat(token.volume).toFixed(2)}</Td>
              <Td isNumeric>{parseFloat(token.volume1d).toFixed(2)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
