import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import dotenv from "dotenv";

dotenv.config();


export const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_SUBGRAPH_URL,
  }),
  cache: new InMemoryCache(),
});
